import '../../styles/SkillBubble.css'



const SkillBubble = ({skill})=>{


    return(
    <>
     <span className = "skill-bubble">{skill}</span>
    
    </>

    )
}
export default SkillBubble